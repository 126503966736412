import React from "react";
import {default as ButtonBase} from "@material-ui/core/Button";
import "./style.styl";


export default function Button(props) {
    const {isWhite, type} = props;
    return (
        <ButtonBase  {...props} variant="outlined" className={"button-custom " + props.className + " " + type + " " + (isWhite ? "white" : "")}/>
    );
}
